@import '../../styles/core.scss';

.pl-table {
  .ant-table {
    &-cell {
      &::before {
        width: 0px !important;
      }
    }

    &-column-sort {
      background: #fff;
    }

    &-thead {
      .ant-table-cell {
        color: $gray2;
      }

      tr {
        th {
          background: #fff;
        }
      }
    }

    &-tbody {
      .name-text {
        font-weight: 600;
        color: $blue !important;
      }

      .ant-table-cell {
        @include font-size(16px);
        color: $gray1;
      }

      tr,
      td {
        .ant-table-row:hover,
        .ant-table-cell-row-hover {
          cursor: pointer;
          background: $primary_hover !important;
        }
      }
    }
  }

  .no-data-text {
    color: $gray3;
  }
}
