@import './src/styles/core.scss';

.service-block {
  position: relative;

  &-dynamic-form {
    display: flex;
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid $gray3;
  }

  &-50 {
    width: calc(50% - 12px);
  }

  .block-divider {
    border-left: 1px solid $gray3;
    align-self: stretch;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .delete-service {
    color: $negative;
    position: absolute;
    top: -12px;
    right: -12px;
  }
}
