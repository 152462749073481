@import '../../../../../../styles/core';

.media-content {
  margin-top: $spacing-m;

  .ant-table-filter-trigger {
    position: absolute;
    padding: 6px;
    top: -34px;
    right: 10px;
    border-radius: 8px;
    border: 2px solid $primary;
  }

  .ant-table-cell {
    position: static;
  }

  .media-content-search {
    width: calc(100% - 16px - 42px); // 100% - margin - icon width
  }
}
