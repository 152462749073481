@import './src/styles/core';

.pl-upload-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0 4px 12px;
  border: 0.5px solid $gray3;
  border-left-width: 0;
  border-right-width: 0;

  .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 70%;

    svg {
      min-width: 16px;
      min-height: 16px;
    }

    &-name {
      margin-left: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
