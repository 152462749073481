.forgot-modal {
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .input-container {
    width: 100%;
  }

  .forgot-input {
    height: 42px;
  }
}
