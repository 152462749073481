.set-password-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-form-item-explain {
    &-warning:not(:first-child) {
      // shows only one warning
      display: none;
    }
  }

  .set-password-input {
    min-width: 232px;
    width: 24vw;
  }
}
