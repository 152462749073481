@import '../../../styles/core';

.admin-details {
  &-wrapper {
    &-actions {
      display: flex;
    }

    .ant-form-item {
      width: 50%;
      margin-right: 24px;
    }
  }
}
