@import './src/styles/core.scss';

.close-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 14px;
    height: 2px;
    background-color: $gray1;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
