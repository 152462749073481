@import '../../styles/core.scss';

.pl-button {
  &.ant-btn-text {
    :hover {
      opacity: 0.7;
    }

    :active {
      opacity: 0.6;
    }
  }

  &:disabled {
    color: $gray3;
    background-color: $gray4;
    border-color: $gray4;
  }

  &.negative {
    color: $negative;
  }

  &.no-styles {
    margin: 0;
    padding: 0;
    height: auto;
  }
}
