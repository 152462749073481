@import '../../../../../../styles/core';

.create-survey-form {
  &-questions {
    .add-question-button {
      position: absolute;
      bottom: 40px;
      right: 64px;
    }
  }
}

.dynamic-form-block {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid $gray3;

  &-50 {
    width: calc(50% - 12px);
  }

  .dynamic-form-divider {
    border-left: 1px solid $gray3;
    align-self: stretch;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .delete-form-item {
    color: $negative;
    position: absolute;
    top: 12px;
    right: -12px;
  }
}
