@import '../../styles/core.scss';

.custom-pt-drawer {
  height: 100vh;
  position: sticky;
  top: 0;

  @at-root.ant-tooltip {
    &-placement-right {
      left: 70px !important;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .logo {
    margin: 24px 20px;

    img {
      height: 32px;
      width: 32px;
    }
  }

  &,
  .ant-menu-dark {
    background: $gray1;
  }

  .ant-menu-item {
    padding: 0 !important;
    margin: 0 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    border-radius: 8px;
    margin-bottom: 4px !important;

    .ant-menu-title-content {
      display: none !important;
    }

    &-selected {
      background-color: $gray1_selected !important;

      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  .drawer-footer {
    margin-top: auto;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-logout {
      margin-bottom: 32px;
      cursor: pointer;

      svg {
        color: #fff;
      }
    }

    .settings-wrapper {
      cursor: pointer;
    }

    &-circle {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: $gray1_selected;
      margin: auto;
      border-radius: 20px;
      color: $gray3;
      font-size: 16px;
      font-weight: bold;
      margin-top: 16px;
    }
  }
}
