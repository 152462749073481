@import '../../styles/core.scss';

.admins-wrapper {
  .row-disabled {
    background-color: $gray4;

    .ant-table-column-sort {
      background-color: $gray4;
    }
  }
}
