@import '../style';
@import '../../../styles/core.scss';

.pl-input {
  @include font-size(16px);
  color: $gray1;
  width: 100%;

  &.ant-input {
    &-disabled {
      @include field-disabled-styles;
    }
  }

  &.no-border {
    background-color: transparent !important;
    color: $gray1;
    border-width: 0;
    padding-left: 0;
  }

  &.no-disabled-styles {
    cursor: pointer;
    pointer-events: all;
    background-color: transparent !important;
    color: $gray1;
    border: 1px solid $gray3;
    border-radius: 4px;

    &:hover {
      border-color: $gray2;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}
