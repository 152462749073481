.answer-field {
  display: flex;
  width: 100%;
  align-items: center;

  .ant-form-item {
    width: 100%;
  }

  .delete-answer {
    margin-left: 16px;
  }
}
