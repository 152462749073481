@import 'src/styles/core.scss';

.pl-checkbox {
  width: 100%;

  .ant-checkbox-wrapper {
    &.ant-checkbox-group-item {
      margin-bottom: 12px;
      width: 50%;
      margin-right: 0;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $secondary;
    border-color: $secondary !important;

    &.ant-checkbox-inner {
      &:after {
        border-color: $white !important;
      }
    }
  }
}
