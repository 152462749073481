@import '../../../../../../../styles/core';

.preview-media-block {
  .ant-image {
    position: absolute;
    display: none;
  }

  .preview-media-button {
    color: $blue;
  }
}

.preview-modal-wrapper {
  top: 8px;

  .ant-modal-body {
    padding: 46px !important;
  }
}

.preview-video-modal-wrapper {
  padding: 0 !important;
  position: relative;
  bottom: 0;
  right: 0;

  &-div {
    padding: 0 !important;
    position: relative;
  }

  .ant-modal-body {
    padding: 0 !important;
    position: relative;
  }
}
