@import '../../styles/core.scss';

.patient-wrapper {
  &-search-input {
    display: flex;
    justify-content: space-between;
  }

  &-title {
    display: flex;
    align-items: center;
  }

  .row-disabled {
    background-color: $gray4;

    .ant-table-column-sort {
      background-color: $gray4;
    }
  }
}
