@import '../../../styles/core.scss';

.pl-switch {
  height: 28px;
  min-width: 52px;

  &-label {
    font-size: 16px;
    line-height: 19.5px;
    color: $gray1;
    padding-left: $spacing-xs;
    display: inline-flex;
    align-items: center;
  }

  .ant-switch-handle {
    height: 24px;
    width: 24px;

    &:before {
      border-radius: 12px;
    }
  }

  &.ant-switch {
    background: $gray2;
  }

  &.ant-switch-checked {
    background: $secondary;

    // 24px -> width of the ant-switch-handle
    // 2px -> just to move ant-switch-handle 2px inside
    .ant-switch-handle {
      left: calc(100% - 24px - 2px);
    }
  }
}
