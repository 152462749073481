.pl-modal {
  .ant-modal {
    &-content {
      border-radius: 16px;
    }

    &-body {
      padding: 56px 64px 40px;
    }

    &-close {
      top: 16px;
      right: 16px;
    }
  }
}
