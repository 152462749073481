.group-wrapper {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.width-25 {
  .ant-form-item {
    width: calc(25% - 12px);
  }
}

.width-33 {
  .ant-form-item {
    width: calc(33.3333% - 12px);
  }
}

.width-50 {
  .ant-form-item {
    width: calc(50% - 12px);
  }
}

.width-100 {
  .ant-form-item {
    width: 100%;
  }
}
