@import 'src/styles/core';

.pl-text-cell {
  &-default {
    color: $gray1 !important;
  }
  &-gray2 {
    color: $gray2 !important;
  }
  &-primary {
    color: $blue !important;
  }
}
