@import './src/styles/core.scss';

.pdf-viewer-wrapper {
  @include scrollbar;
  width: 100%;
  height: 70vh;
  overflow: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  user-select: text !important;
  box-shadow: 0 0 20px 0.1px rgb(219, 216, 216);
}

.action-buttons-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 12px;

  .next-button {
    margin-left: 16px;
  }
}
