.video-viewer-wrapper {
  top: 0;
  right: 0;
  position: absolute;

  .video-viewer-window {
    height: 100%;
    width: 100%;
    border-radius: 16px;
  }
}
