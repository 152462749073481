@import '../style';

.pl-select {
  &.ant-select-disabled {
    cursor: unset;

    .ant-select-selector {
      @include field-disabled-styles;
    }

    .ant-select-arrow svg path {
      fill: $gray3;
    }
  }

  &.hide-label {
    .ant-select-selection-item {
      display: none;
    }
  }

  .ant-select-arrow-loading svg {
    width: 20px;
    height: 20px;
  }
}
