@import '../../styles/core.scss';

.pl-modal {
  width: 50vw !important;

  @media (max-width: 767px) {
    width: inherit !important;
    max-width: calc(100vw - 16px) !important;
  }

  .ant-modal {
    padding: 40px 54px;

    &-body {
      padding: 40px 64px;
    }

    &-content {
      border-radius: 16px;
    }

    &-close {
      overflow: hidden;
      border-radius: 16px;
      top: 8px;
      right: 8px;

      &:hover {
        opacity: 0.6;
      }

      &-x {
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
