@import './src/styles/core';

.pl-media-list {
  @include scrollbar;
  border: 1px solid $gray3;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: calc((22px * 2 + 20px) * 3);
  padding-right: 4px;
  overflow-y: auto;

  .ant-spin-nested-loading {
    width: 100%;
  }

  &-spin {
    padding: 12px;
    align-self: center;
  }

  &-item {
    width: 100%;
    cursor: pointer;
    text-align: start;
    background-color: $white;
    padding: 22px 26px;
    border-width: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-icon {
      margin-right: 18px;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background: rgb(15, 68, 69, 0.05);
    }

    &-selected {
      background: rgb(15, 68, 69, 0.1) !important;
    }

    &:active {
      background: rgb(15, 68, 69, 0.15) !important;
    }
  }
}
