.login-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-input {
    min-width: 232px;
    width: 24vw;
  }
}
