@import '../styles/core.scss';

.pl-layout {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .header-breadcrumb-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .pl-layout-title {
    padding: 16px 0 24px 0;
    display: flex;

    span {
      margin-left: 8px;
    }
  }

  .right-side-actions {
    position: absolute;
    top: 32px;
    right: 0;
  }

  .site-layout .site-layout-background {
    background: #fff;
    flex: 1;
  }

  &-header {
    padding: 12px 24px 24px 0px;

    .ant-page-header-heading-left {
      margin: 0;
    }
  }

  .ant-layout-footer {
    @include font-size(16px);
    color: #898181;
  }
}
