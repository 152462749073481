@import '../../styles/core';

@mixin field-disabled-styles {
  background-color: $gray4 !important;
  pointer-events: none;
  border: none;
}

.app-view-text {
  @include font-size(16px);
  line-height: 19.5px;
  color: $gray1;
  word-wrap: break-word;

  &-blue {
    color: $blue;
  }
}

.ant-form-item-tooltip {
  width: 18px;
  height: 18px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.required-star {
  .ant-form-item-label {
    height: 30px;
    padding-bottom: 8px !important;
    align-items: center;
    display: flex;
    width: 100%;

    &:after {
      display: block;
      content: '*';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #f33938;
    }
  }
}
