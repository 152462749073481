.login-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100vh;
  padding: 32px 32px 0;
  background-color: #00aac6;

  .wrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 32px;
  }

  .image {
    align-self: center;
    text-align: center;
  }

  .title-text {
    font-size: 24px;
    font-weight: bold;
    margin-top: 44px;
    color: #00aac6;
  }
}
