@import '../../styles/core';

.pl-tabs {
  flex: 1;

  &-scrolling {
    .ant-tabs-nav-list {
      flex: 1;
      @include scrollbar;
      padding-bottom: 2px;
      cursor: grab;
      overflow-x: auto;
      max-width: calc(100vw - 80px - 72px); // 100% - left side nav - main paddings
    }

    :before {
      display: none;
    }
  }

  .ant-tabs-nav:before,
  .ant-tabs-nav-operations {
    display: none !important;
  }

  &.ant-tabs {
    .ant-tabs-tab {
      border-radius: 8px !important;
      padding: 4px 16px;
      border-width: 0;
      background-color: transparent;

      &-active {
        background-color: $primary;

        .ant-tabs-tab-btn {
          color: $white;
        }
      }
    }
  }
}
