@import '../../styles/core';

.confirm-modal {
  min-width: 50% !important;

  .button-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .cancel-btn {
    color: $gray2;
    border-width: 2px;
    border-color: $gray2;
    margin-right: 16px;
  }
}
