@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import './reset.scss';
@import './core.scss';

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  hr {
    border: 0.5px solid $gray3;
  }

  .ant-form-item-label label {
    color: $gray2;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
    margin-right: 0px;
  }

  .color-box {
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }

  &.block-scroll {
    height: 100%;
    overflow: hidden;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  .modal-title {
    @include font-size(24px);
    font-weight: 700;
    line-height: 29px;
    color: $gray1;
  }

  .pr-12 {
    padding-right: 12px;
  }

  .pr-24 {
    padding-right: 24px;
  }

  .page-title {
    @extend .modal-title;
  }

  .modal-description {
    @include font-size(16px);
    line-height: 19.5px;
    color: $gray2;
  }

  .modal-form-title,
  .form-title {
    @include font-size(16px);
    color: $gray1;
    font-weight: 700;
    line-height: 19.5px;
  }

  .page-content {
    &-title {
      @extend .modal-form-title;
    }

    &-description {
      margin-top: $spacing-s;
      @extend .modal-description;
    }
  }

  .status-provider {
    height: 20px;
    border: 1px solid $gray2;
    padding: 0 8px;
    border-radius: 16px;
    color: $gray2;
    font-size: 14px;
    line-height: 17px;

    &.active {
      color: $positive;
      border: 1px solid $positive;
    }

    &.pending {
      color: $secondary;
      border: 1px solid $secondary;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border-radius: 8px;
    padding: 12px;

    span {
      font-weight: 600;
      @include font-size(16px);
      line-height: 19.5px;
      height: 20px;
    }

    &-default {
      color: $gray2;
      border: 2px solid $gray2;
      border-radius: 8px;
      background-color: $white;
    }

    &-ghost {
      color: $gray2;
      border: 2px solid $gray2;
      border-radius: 8px;
    }

    &-text {
      &:hover {
        background-color: transparent;
        opacity: 0.7;
      }

      &:active,
      &:focus,
      &:checked {
        background-color: transparent;
        opacity: 1;
      }
    }
  }

  .pl-page-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
