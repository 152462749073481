@import './src/styles/core.scss';

$success_background: #d6f6e8;
$success_text: #005731;
$success_icon: #00a65d;

$error_background: #ffdbda;
$error_text: #960303;
$error_icon: #f33938;

$great_background: #d7edff;
$great_text: #09487b;
$great_icon: $blue;

$missing_background: #e1e4e8;
$missing_text: $gray1;
$missing_icon: #535a5f;

$warning_background: #fff0d0;
$warning_text: #654a10;
$warning_icon: #f3bb41;

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  border-radius: 40px;
  height: auto;
  width: auto;
  min-width: 292px; // min mobile width
  padding: 8px 16px 8px 8px;

  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ant-notification-notice-message {
      font-weight: bolder;
      margin: 0;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      width: calc(100% - 32px - 16px);
    }

    .ant-notification-notice-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
      min-width: 32px;
      min-height: 32px;
      margin: 0 16px 0 0;
      border-radius: 50%;
    }
  }

  .ant-notification-notice-close {
    display: flex;
    position: static;
    padding: 8px;
    align-items: center;
  }

  .ant-notification-notice-description {
    display: none;
  }
}

.success {
  background-color: $success_background;

  .ant-notification-notice-message {
    color: $success_text;
  }

  .ant-notification-notice-icon {
    background-color: $success_icon;
  }
}

.error {
  background-color: $error_background;

  .ant-notification-notice-message {
    color: $error_text;
  }

  .ant-notification-notice-icon {
    background-color: $error_icon;
  }
}

.great {
  background-color: $great_background;

  .ant-notification-notice-message {
    color: $great_text;
  }

  .ant-notification-notice-icon {
    background-color: $great_icon;
  }
}

.missing {
  background-color: $missing_background;

  .ant-notification-notice-message {
    color: $missing_text;
  }

  .ant-notification-notice-icon {
    background-color: $missing_icon;
  }
}

.warning {
  background-color: $warning_background;

  .ant-notification-notice-message {
    color: $warning_text;
  }

  .ant-notification-notice-icon {
    background-color: $warning_icon;
  }
}
