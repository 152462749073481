@import '../../../styles/core';

.ant-modal {
  &-header {
    border-radius: 16px;
    border: none;
  }

  &-content {
    border-radius: 16px;
  }

  &-footer {
    border: none;
    display: flex;
    justify-content: flex-start;
  }
}

.without-margin {
  margin-bottom: 0;
  width: 100% !important;

  &.ant-form-item-has-error {
    .ant-input-status-error {
      border-color: $negative;
    }
  }
}

.pl-upload {
  &.hide {
    display: none;
  }

  // DRAGGER
  &.ant-upload {
    &-drag {
      border: none;
      background: $gray4;
      border-radius: 4px;

      .ant-upload-btn {
        padding: 64px;
      }
    }

    &:hover {
      opacity: 0.75;
    }

    &:active,
    &:checked,
    &:focus {
      opacity: 1;
    }
  }

  // SELECT
  .ant-upload {
    &-select {
      width: 100%;

      .ant-upload {
        justify-content: space-between;
        display: flex;
        column-gap: 16px;
      }
    }
  }
}
