.pl-icon-button {
  padding: 6px;
  cursor: pointer;
  display: flex;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
}
