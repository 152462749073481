@import '../../../styles/core.scss';

.pl-color-picker {
  display: flex;
  align-items: center;
  width: 100%;

  .pl-input {
    margin: 0 16px;
  }

  &-input {
    position: absolute;
    visibility: hidden;
  }
}
