@import 'colors';

.font {
  &-h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $gray1;
  }

  &-paragraph {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $gray1;
  }

  &-button {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $blue;
  }

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray2;
  }

  &-color {
    &-gray1 {
      color: $gray1;
    }

    &-gray2 {
      color: $gray2;
    }
  }
}
