@use 'sass:math';
@import 'colors';

@mixin fill-absolute {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

@function calculateRem($size) {
  $remSize: math.div($size, 16px);
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: calculateRem($size);
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    margin: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $gray2;
    background-clip: content-box;
  }
}
