$gray1: #1d242d;
$gray1_selected: #ffffff33;
$gray2: #787d83;
$gray3: #cdd6e1;
$gray4: #f1f7ff;
$primary: #0f4445;
$primary_hover: #0f44451a;
$positive: #00a65d;
$negative: #f33938;
$secondary: #00aac6;
$white: #ffffff;
$black: #000000;
$blue: #1293fd;
